import React, { useState } from "react";
import { getValue } from "@utils/lodash";
// import { convertCurrentDate, convertCurrentDateWithTime } from "@_common/date-helpers";
import "./view.scss";
// import DownloadSubheaderSvgComponent from "../../../assets/svg-components/sub-header/download";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
// import moment from "moment";
import base64 from "base-64";
import moment from "moment-timezone";
import "moment-timezone";
import DocViewer, { PDFRenderer, PNGRenderer } from "react-doc-viewer";
import { handleDownloadOnClick } from "@_common/downloadfile";

function BlogsViewPopup(props: any) {
  const { showBlogsPopup, setShowBlogsPopup } = props;
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  // const docs = [
  //   { uri: require("./sample.pdf") }, // Local File
  // ];

  //   const convertCurrentDate = (date: any) => {
  //     if (date) {
  //       return moment(date).tz(timezone).format("DD MMM YYYY");
  //       // return moment.utc(date).format("DD MMM YYYY");
  //     } else {
  //       return null;
  //     }
  //   };

  const convertDateAsString = (date: any) => {
    if (date) {
      return moment(date).format("DD MMM YYYY");
    } else {
      return null;
    }
  };

  let user = getValue(localStorage, `user`, "")
    ? JSON.parse(getValue(localStorage, `user`, {}))
    : {};
  let timezone = getValue(user, `user_timezone`, "")
    ? getValue(user, `user_timezone`, "")
    : "Asia/Kolkata";

  const convertCurrentDateWithTime = (date: any) => {
    if (date) {
      // return moment.utc(date).format("DD MMM YYYY, hh:mm a");
      return moment(date).tz(timezone).format("DD MMM YYYY, hh:mm a");
      // return moment.utc(date).format("DD MMM YYYY, hh:mm a");
    } else {
      return null;
    }
  };

  const content = getValue(props, `blogInfo.content`, "");
  // const decodeBase64 = (content: any) => {
  //   let decodedContent;
  //   try {
  //     decodedContent = base64.decode(content);
  //   } catch (error) {
  //     console.error("Error decoding base64:", error);
  //     decodedContent = content; // Fallback to original content if decoding fails
  //   }
  //   return decodedContent;
  // };

  // Function to render HTML content safely using dangerouslySetInnerHTML
  const renderHTML = (htmlContent: any) => {
    return { __html: htmlContent };
  };

  return (
    <>
      <div
        className={`compelte-task-wrapper compelte-task-wrapper-add ${
          showBlogsPopup ? "compelte-task-wrapper--active" : ""
        }`}
        style={{ maxWidth: "50%" }}
      >
        <div className="compelte-task-wrapper__header d-flex align-items-center justify-content-between">
          <h6 className="compelte-task-wrapper__title">View Legal Update</h6>
          <img
            src="/images/close-white.svg"
            className="img-fluid navigation-bar-wrapper__close-btn"
            onClick={() => {
              setShowBlogsPopup(false);
            }}
          />
        </div>

        <div className="d-flex max-height-blog view-blog-wrapper">
          <div className="view-blog-content-wrapper m-0 w-100">
            <div className="blog-content">
              <div className="blog-title">
                {getValue(props, `blogInfo.title`, "")}
              </div>
              <div className="created-author-wrap d-flex">
                <span className="autor-name time-stamp">
                  <span className="font-timestamp-color">Author Name :</span>
                  <b>&nbsp;{getValue(props, `blogInfo.author`, "")}</b>
                </span>

                <span className="time-stamp ">
                  <span className="font-timestamp-color">Created At :</span>
                  <b>
                    {" "}
                    {convertCurrentDateWithTime(
                      getValue(props, `blogInfo.created_at`, "")
                    )}
                  </b>
                </span>

                <span className="autor-name time-stamp">
                  <span className="font-timestamp-color">Created By :</span>
                  <b>
                    {" "}
                    {getValue(props, `blogInfo.created_by.first_name`, "")} {""}{" "}
                    {getValue(props, `blogInfo.created_by.last_name`, "")}
                  </b>
                </span>
                <span className="time-stamp border-0">
                  <span className="font-timestamp-color">Updated At :</span>
                  <b>
                    {" "}
                    {convertCurrentDateWithTime(
                      getValue(props, `blogInfo.updated_at`, "")
                    )}
                  </b>
                </span>
              </div>

              <div className="created-author-wrap d-flex">
                {getValue(props, `blogInfo.act_name.text`, "") && (
                  <span className="autor-name time-stamp">
                    <span className="font-timestamp-color">Act Name :</span>
                    <b>{getValue(props, `blogInfo.act_name.text`, "")}</b>
                  </span>
                )}

                <span className="autor-name time-stamp">
                  <span className="font-timestamp-color">
                    Publication Date :
                  </span>
                  <b>
                    &nbsp;{convertDateAsString(getValue(props, `blogInfo.publication_date`, ""))}
                  </b>
                </span>
                <span className="autor-name">
                  <span className="font-timestamp-color">Effective Date :</span>
                  <b>&nbsp;{convertDateAsString(getValue(props, `blogInfo.effective_date`, ""))}</b>
                </span>
              </div>

              <div className="blog-tags-wrap d-flex">
                {/* {getValue(props, `blogInfo.tags`, []).map(
                  (tagObj: any, index: any) => {
                    return (
                      <>
                        <div className="legal-tags">
                          {getValue(tagObj, `text`, "")}
                        </div>
                      </>
                    );
                  }
                )} */}

                {getValue(props, `blogInfo.tags`, []).map((tagObj: any, index: any) => (
                    <div className="legal-tags" key={tagObj.id || index}>
                      {getValue(tagObj, `text`, "")}
                    </div>
                  ))}

                <div
                    className="legal-tags states-name-tags"
                    title={`${getValue(props, 'blogInfo.country.name', '')} - ${getValue(props, 'blogInfo.states.name', '')}`}
                    >
                    {getValue(props, `blogInfo.country.name`, "")} - {getValue(props, `blogInfo.states.name`, "")}
                  </div>

              </div>
              <div className="border-top my-3"></div>
              <div
                className="blog-desc"
                dangerouslySetInnerHTML={renderHTML(content)}
              />

              {getValue(props, `blogInfo.document_link`, "") && (
                <div className="blog-docs-wrap d-flex align-items-center">
                  <a
                    href={getValue(props, `blogInfo.document_link`, "")}
                    className="cursor-pointer"
                    target="_blank"
                  >
                    View Document URL
                  </a>
                </div>
              )}

              {/* {getValue(props, `blogInfo.documents`, "") && (
                <>
                  {getValue(props, `blogInfo.documents`, "").map(
                    (item: any) => (
                      <div
                        className="blog-docs-wrap d-flex align-items-center cursor-pointer"
                        onClick={() =>
                          handleDownloadOnClick(item.url, item.name)
                        }
                      >
                        View Uploaded Document
                      </div>
                    )
                  )}
                </>
              )} */}

              {getValue(props, `blogInfo.documents`, "") && (
                <>
                  {getValue(props, `blogInfo.documents`, "").map((item: any, index: any) => (
                    <div
                      className="blog-docs-wrap d-flex align-items-center cursor-pointer"
                      key={item.id || index}
                      onClick={() => handleDownloadOnClick(item.url, item.name)}
                    >
                      View Uploaded Document
                    </div>
                  ))}
                </>
              )}

            </div>
            <div className="compelte-task-wrapper__cta d-flex align-items-center">
              <button
                className="ascent-button ascent-button--primary"
                onClick={() => setShowBlogsPopup(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>
          Document For {getValue(props, `blogInfo.title`, "")}
        </ModalHeader>
        <ModalBody>
          <iframe
            src={getValue(props, `blogInfo.document_link`, "")}
            title="Document URL"
            className="document-url-iframe"
          ></iframe>
        </ModalBody>
      </Modal> */}
    </>
  );
}

export default BlogsViewPopup;
