import React, { useState, useEffect, useRef } from "react";
import qs from "query-string";
import { getValue, setValue } from "@utils/lodash";
import {
  adminDeleteSpecificRole,
  adminGetRoles,
  deleteSpecificLegal,
  legalUpdate,
} from "@services/roles.service";
import ReactTooltip, { Tooltip } from "react-tooltip";
import Notfound from "@components/Not-Found";
import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import { useNavigate, useParams } from "react-router-dom";
import DataTable from "@components/DataTable/index";
import NavigationBar from "@components/NotificationBar";
import PaginationPage from "@components/pagination/pagination";
import "./index.scss";
import SearchHeader from "@components/common/SearchHeader";
import { NavLink } from "react-router-dom";
import { Button } from "reactstrap";
import DeleteModal from "@_common/deleteModal";
import { toast } from "react-toastify";
import Loader from "@components/common/Loader";
// import moment from "moment";
import { adminBulkRoleUpdateStatus } from "@services/auth.service";
import NoAccessPage from "@components/common/NoAccess";
import ScrollableReactTable from "@components/ScrollableDatatable";
import { QueryRequestHelper } from "@_common/query-request-helper";
import LegalFilterForm from "./filter";
import {
  adminGetCountriesListManagement,
  adminGetListManagementGroupCode,
  adminGetStatesListManagement,
  adminListManagementList,
} from "@services/list-management.service";
import BlogsViewPopup from "./view";
import EditSvgComponent from "../../../assets/svg/edit";
import ViewMoreSvgComponent from "../../../assets/svg/view-more";
import DeleteSvgComponent from "../../../assets/svg/delete";
import base64 from "base-64";
import moment from "moment-timezone";
import "moment-timezone";
import { handleDownloadOnClick } from "@_common/downloadfile";
import {
  adminExportLegalUpdate,
  adminImportLegalUpdate,
} from "@services/checkpoint.service";
import CommonDateRange from "@components/common/DateRange";

interface ILegalUpdateProps {}

const LegalUpdate: React.FunctionComponent<ILegalUpdateProps> = (
  props: any
) => {
  let navigate = useNavigate();
  const urlParamsFun = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let request = QueryRequestHelper({ ...params });
    return request;
  };

  const mainQueryRequest = urlParamsFun();


  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */

  const [isLoading, setIsLoading] = React.useState(true);
  // const [legalList, setLegalList] = useState([]);
  const [legalList, setLegalList] = React.useState<[]>([]);
  const [isFilterVisible, setFilterVisibility] = React.useState(false);

  const [effective_date_from, setEffectiveDateFrom] = useState<Date | null>(null);
  const [effective_date_to, setEffectiveDateTo] = useState<Date | null>(null);



  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (Object.keys(params).length === 0 && props.permissionAPITriggered) {
      getData();
      // getCountriesList();
      // getStatesList(request.country_id);
      getStateList("", "");
      getTagsList("", "");
      getIndustryList("", "");
      let dates = params.date ? new Date(params.date) : new Date();
      var from_date = new Date(dates.getFullYear(), dates.getMonth(), 1);
      var to_date = new Date(dates.getFullYear(), dates.getMonth() + 1, 0);
      setFirstDay(from_date);
      setLastDay(to_date);

    }
  }, [props.permissionAPITriggered]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (Object.keys(params).length > 0 && props.permissionAPITriggered) {
      getData();
      // getCountriesList();
      // getStatesList(request.country_id);
      getStateList("", "");
      getTagsList("", "");
      getIndustryList("", "");

    
      if (params.search_text) {
        setSearchInput(params.search_text);
        setShowSearchTextbox(true);
      }
      if (params.page_no) {
        setPage_no(parseInt(params.page_no));
      }
      if (params.limit) {
        setLimit(parseInt(params.limit));
      }
      if (params.from_date && params.to_date) {
        // setDate(new Date(params.date));
        var from_date = new Date(params.from_date);
        var to_date = new Date(params.to_date);
        setFirstDay(from_date);
        setLastDay(to_date);
      }
    }
  }, [window.location.href, props.permissionAPITriggered]);

  const handleEffectiveDate = (date: any) => {
    setEffectiveDateFrom(date[0]);
    setEffectiveDateTo(date[1]);
  };


  const [smartFilterList, setSmartFilterList] = useState([]);
  const [selectedSmartFilterIds, setSelectedSmartFilterIds] = useState<any>([]);

  const getSmartFilterList = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    let selectedIDs: any = urlSearchParams.getAll("smart_filter_tags[]");

    try {
      let resp = await adminGetListManagementGroupCode("smart-filter");
      if (resp) {
        let obj = getValue(resp, `data.listItems`, []).map((item: object) => ({
          id: getValue(item, `id`, ""),
          is_active: getValue(item, `is_active`, ""),
          text: getValue(item, `text`, ""),
          value: getValue(item, `text`, ""),
          label: getValue(item, `text`, ""),
        }));
        setSmartFilterList(obj);
        if (getValue(selectedIDs, `length`, 0) > 0) {
          let arr: [] | any = [];
          selectedIDs.map((item: object) => {
            let filtered = obj.filter((ob: any) => ob.id == item);
            if (getValue(filtered, `length`, 0) > 0) {
              arr.push(filtered[0]);
            }
          });
          setSelectedSmartFilterIds(arr);
        }
      } else {
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const convertDate = (date: any) => {
    if (date) {
      return moment(date).format("YYYY-MM-DD");
    } else {
      return null;
    }
  };
  const getPayload = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let industrySelectedIDs: any = urlSearchParams.getAll("industries[]");
    let regulationSelectedIDs: any = urlSearchParams.getAll("regulations[]");
    let ministrySelectedIDs: any = urlSearchParams.getAll("ministries[]");
    let departmentSelectedIDs: any = urlSearchParams.getAll("departments[]");
    let statesSelectedIDs: any = urlSearchParams.getAll("states[]");
    let tagsSelectedIDs: any = urlSearchParams.getAll("tags[]");
    let notificationTypeSelectedIDs: any = urlSearchParams.getAll(
      "notification_type[]"
    );

    // let notificationTypeSelectedIDs: any = urlSearchParams.getAll("notification_type");

    // let compliance_task_ids: any = urlSearchParams.getAll(
    //   "compliance_task_ids[]"
    // );

    let dates = params.date ? new Date(params.date) : new Date();
    var from_date = params.from_date
      ? params.from_date
      : new Date(dates.getFullYear(), dates.getMonth(), 1);
    var to_date = params.to_date
      ? params.to_date
      : new Date(dates.getFullYear(), dates.getMonth() + 1, 0);

    let payload: any = {
      from_date: getValue(params, `is_all_time`, "")
        ? ""
        : getValue(params, `from_date`, "")
        ? getValue(params, `from_date`, "")
        : convertDate(from_date),
      to_date: getValue(params, `is_all_time`, "")
        ? ""
        : getValue(params, `to_date`, "")
        ? getValue(params, `to_date`, "")
        : convertDate(to_date),
      // compliance_status: getValue(params, `compliance_status`, ""),
      page_no: params.page_no ? parseInt(params.page_no) : 1,
      page_size: params.limit ? parseInt(params.limit) : limit,
      sort_by: params.sort_by,
      search_text: params.search_text,
      country_id: parseInt(getValue(params, `country_id`, "")),
      state_id: parseInt(getValue(params, `state_id`, "")),
      // compliance_type_id: getValue(params, `compliance_type_id`, ""),
      // self_compliance_task_ids: compliance_task_ids,
      industries:
        industrySelectedIDs.length > 0
          ? industrySelectedIDs.map((item: string) => ({ id: item }))
          : [],
      regulations:
        regulationSelectedIDs.length > 0
          ? regulationSelectedIDs.map((item: string) => ({ id: item }))
          : [],
      ministries:
        ministrySelectedIDs.length > 0
          ? ministrySelectedIDs.map((item: string) => ({ id: item }))
          : [],
      departments:
        departmentSelectedIDs.length > 0
          ? departmentSelectedIDs.map((item: string) => ({ id: item }))
          : [],
      states:
        statesSelectedIDs.length > 0
          ? statesSelectedIDs.map((item: string) => ({ id: item }))
          : [],
      tags:
        tagsSelectedIDs.length > 0
          ? tagsSelectedIDs.map((item: string) => ({ id: item }))
          : [],
      notification_type:
        notificationTypeSelectedIDs.length > 0
          ? notificationTypeSelectedIDs.map((item: string) => ({ id: item }))
          : [],
      // notification_type : notificationTypeSelectedIDs[0]

      // status: getValue(params, `status`, "")
      //   ? getValue(params, `status`, "")
      //   : "active",
      // request_from:
      //   getValue(compliance_task_ids, `length`, 0) > 0
      //     ? ""
      //     : getValue(params, `request_from`, "")
      //     ? getValue(params, `request_from`, "")
      //     : "MASTER",
    };
    return { ...payload };
  };

  const getData = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    let dates = params.date ? new Date(params.date) : new Date();
    var from_date = params.from_date
      ? params.from_date
      : new Date(dates.getFullYear(), dates.getMonth(), 1);
    var to_date = params.to_date
      ? params.to_date
      : new Date(dates.getFullYear(), dates.getMonth() + 1, 0);


    let payload: any = {
      page_no: params.page_no ? parseInt(params.page_no) : 1,
      page_size: params.limit ? parseInt(params.limit) : limit,
      search_text: params.search_text,
      sort_by: params.sort_by,
      is_active: true,
      list: true,
      from_date:getValue(params, `from_date`, "")
        ? getValue(params, `from_date`, "")
        : convertDate(from_date),
      to_date: getValue(params, `to_date`, "")
        ? getValue(params, `to_date`, "")
        : convertDate(to_date),
      effective_from_date: getValue(params, `effective_date_from`, "") ? getValue(params, `effective_date_from`, "") : convertDate(effective_date_from),
      effective_to_date: getValue(params, `effective_date_to`, "") ? getValue(params, `effective_date_to`, "") : convertDate(effective_date_to),
    };
    // removing null values
    Object.keys(payload).forEach((key) => {
      if (!payload[key] || payload[key] === undefined) {
        delete payload[key];
      }
    });
    // let queryRequest = qs.stringify(payload);
    let payloadObj = await getPayload();
    let queryRequest = QueryRequestHelper({
      ...payload,
      ...payloadObj,
    });
    try {
      setIsLoading(true);
      let resp = await legalUpdate({ ...payload, ...payloadObj });
      if (resp) {
        // setLegalList(
        // 	getValue(resp, `data.roles`, []).map((item: object) => ({
        // 		...item,
        // 		active: getValue(item, `is_active`, false) ? "Active" : "Inactive",
        // 		id: getValue(item, `id`, ""),
        // 		createdAtFormatted: moment(getValue(item, `created_at`, "")).format(
        // 			"DD MMM YYYY"
        // 		),
        // 	}))
        // );
        setLegalList(getValue(resp, `data.blogData`, []));
        setTotalCount(getValue(resp, `data.pagination.total`, 0));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Search section                               */
  /* -------------------------------------------------------------------------- */
  const [showSearchTextbox, setShowSearchTextbox] = useState(false);
  const [search_text, setSearchInput] = useState<any>("");

  const toggleSearch = () => {
    setShowSearchTextbox((prevState) => !prevState);
  };
  const toggleSearchClose = () => {
    setSearchInput("");
    toggleSearch();
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.search_text) {
      delete params.search_text;
      let payload: any = { ...params };
      // removing null values
      Object.keys(payload).forEach((key) => {
        if (!payload[key] || payload[key] === undefined) {
          delete payload[key];
        }
      });
      let queryRequest = qs.stringify(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
      getData();
    }
  };

  const handleChangeSearch = async (e: any) => {
    setSearchInput(e.target.value);
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let payload: any = {
      ...params,
      page_no: 1,
      search_text: e.target.value,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
    if (!e.target.value) {
      getData();
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                 Delete section                             */
  /* -------------------------------------------------------------------------- */

  const [deleteValue, setDeleteValue] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const handleDeleteFun = (id: string, value: string) => {
    setDeleteValue(value);
    setDeleteId(id);
    handleDelete();
  };
  const [isDelete, setIsDelete] = useState(false);
  const handleDelete = () => {
    setIsDelete(!isDelete);
  };

  const handleDeleteFunction = async () => {
    try {
      let resp = await deleteSpecificLegal(deleteId);
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        handleDelete();
        getData();
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(12);
  const [page_no, setPage_no] = useState(1);

  const handleChangePagination = (page_no: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    params.page_no = page_no;
    Object.keys(params).forEach((key) => {
      if (!params[key] || params[key] === undefined) {
        delete params[key];
      }
    });
    let queryRequest = qs.stringify(params);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  const handleChangeLimit = (limit: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    params.limit = limit;
    Object.keys(params).forEach((key) => {
      if (!params[key] || params[key] === undefined) {
        delete params[key];
      }
    });
    let queryRequest = qs.stringify(params);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const handleChangeSortBy = (value: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    params.sort_by = value;
    props.history.push({
      pathname: window.location.pathname,
      query: params,
    });
  };

  const handleChangeStatus = (value: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    params.status = value;
    props.history.push({
      pathname: window.location.pathname,
      query: params,
    });
  };

  const [selectedIDs, setSelectedIDs] = useState<any>([]);
  const getTableState = (value: object) => {
    let selectedIndexList = Object.keys(
      getValue(value, `selectedRowIds`, {})
    ).map((item) => parseInt(item));
    let selectedIds =
      legalList.length > 0
        ? legalList.filter((_item: object, index: number) =>
            selectedIndexList.includes(index)
          )
        : [];
    if (selectedIds.length > 0) {
      setSelectedIDs(
        selectedIds.map((item: object) => getValue(item, `id`, ""))
      );
    } else {
      setSelectedIDs([]);
    }
  };

  const [check, setCheck] = useState(false);
  const handleRemoveNavItems = () => {
    setCheck(!check);
    setSelectedIDs([]);
  };
  const handleChangeActiveStatus = async (value: string) => {
    try {
      let resp = await adminBulkRoleUpdateStatus({
        ids: selectedIDs,
        is_active: value === "active" ? true : false,
      });
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        setSelectedIDs([]);
        getData();
      } else {
        getData();
      }
    } catch (error) {}
  };
  const getTrProps = (rowInfo: any) => {
    return {};
  };

  const convertDateAsString = (date: any) => {
    if (date) {
      return moment(date).format("DD MMM YYYY");
    } else {
      return null;
    }
  };

  let user = getValue(localStorage, `user`, "")
    ? JSON.parse(getValue(localStorage, `user`, {}))
    : {};
  let timezone = getValue(user, `user_timezone`, "")
    ? getValue(user, `user_timezone`, "")
    : "Asia/Kolkata";

  const convertCurrentDateWithTime = (date: any) => {
    if (date) {
      // return moment.utc(date).format("DD MMM YYYY, hh:mm a");
      return moment(date).tz(timezone).format("DD MMM YYYY, hh:mm a");
      // return moment.utc(date).format("DD MMM YYYY, hh:mm a");
    } else {
      return null;
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                                  Smart Filters                             */
  /* -------------------------------------------------------------------------- */
  const [request, setRequest] = useState<any>({
    country_id: "101",
    state_id: "",
    tags_id: "",
    industry_id: "",
  });

  // useEffect(() => {
  //   getStatesList(101);
  // }, [request.country_id]);

  const [countryList, setCountryList] = useState([]);
  const [states, setStates] = useState([]);

  const reset = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let payload: any = {
      page_no: params.page_no,
      search_text: getValue(params, `search_text`, ""),
      limit: getValue(params, `limit`, ""),
    };
    Object.keys(payload).forEach((key) => {
      if (!payload[key] || payload[key] === undefined) {
        delete payload[key];
      }
    });
    let queryRequest = qs.stringify(payload, {
      arrayFormat: "bracket",
      encode: false,
    });
    navigate(`${window.location.pathname}?${queryRequest}`);
    getData();
    reset1();
  };
  const [resetInput, setResetInput] = useState(false);
  // const reset1 = () => {
  //   setRequest({
  //     ...request,
  //     // country_id: "",
  //     state_id: "",
  //     industry_id: "",
  //     tags_id: "",
  //   });
  //   setSelectedStatesListIds([]);
  //   setSelectedSmartFilterIds([]);
  //   setResetInput(true);
  // };

  const reset1 = () => {
    setRequest({
      ...request,
      state_id: "",
      industry_id: "",
      tags_id: "",
    });
    setSelectedStatesListIds([]);
    setSelectedTagsListIds([]);
    setSelectedIndustryListIds([]);
    setResetInput(true);
    setEffectiveDateFrom(null);
    setEffectiveDateTo(null);

    // setFilterVisibility(false);
  };



  const getCountriesList = async () => {
    try {
      let resp = await adminGetCountriesListManagement();
      if (resp) {
        setCountryList(
          getValue(resp, `data.countries`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `name`, ""),
            label: getValue(item, `name`, ""),
          }))
        );
      } else {
      }
    } catch (error) {}
  };

  const getStatesList = async (countryId: number) => {
    if (countryId)
      try {
        let resp = await adminGetStatesListManagement(countryId);
        if (resp) {
          setStates(
            getValue(resp, `data.states`, []).map((item: object) => ({
              ...item,
              value: getValue(item, `name`, ""),
              label: getValue(item, `name`, ""),
            }))
          );
        } else {
        }
      } catch (error) {}
  };

  /* ---------------------------  Onchange Smart filter  ----------------------------- */

  const handleChangeSelect = (e: any, type: string) => {
    if (type === "country_id") {
      getStatesList(e.id);
      setStates([]);
      setRequest({
        ...request,
        country_id: e.id,
        state_id: "",
        category_of_law_id: "",
        legislation_id: "",
        legislation_rule_id: "",
      });
    }
    if (type === "state_id") {
      setRequest({
        ...request,
        state_id: getValue(e, `id`, "") ? e.id : "",
        category_of_law_id: "",
        legislation_id: "",
        legislation_rule_id: "",
      });
    }
  };

  const handleSubmitSmartFilter = () => {
    // let selectedSmartFilterIdsPayload = JSON.parse(
    //   JSON.stringify(selectedSmartFilterIds)
    // );
    // if (
    //   getValue(request, `state_id`, "")
    //   // && getValue(request, `category_of_law_id`, "") &&
    //   // getValue(request, `legislation_id`, "") &&
    //   // getValue(request, `legislation_rule_id`, "")
    // ) {
    //   const urlSearchParams = new URLSearchParams(window.location.search);
    //   const params = Object.fromEntries(urlSearchParams.entries());

    //   let payload: any = {
    //     page_no: params.page_no,
    //     state_id: getValue(request, `state_id`, ""),
    //     industry_id: getValue(request, `category_of_law_id`, ""),
    //     legislation_id: getValue(request, `legislation_id`, ""),
    //     legislation_rule_id: getValue(request, `legislation_rule_id`, ""),
    //     search_text: getValue(params, `search_text`, ""),
    //     limit: getValue(params, `limit`, ""),
    //     smart_filter_tags:
    //       selectedSmartFilterIdsPayload.length > 0
    //         ? selectedSmartFilterIdsPayload.map((item: object) =>
    //             getValue(item, `id`, "")
    //           )
    //         : [],
    //   };
    //   Object.keys(payload).forEach((key) => {
    //     if (!payload[key] || payload[key] === undefined) {
    //       delete payload[key];
    //     }
    //   });
    //   let queryRequest = qs.stringify(payload, {
    //     arrayFormat: "bracket",
    //     encode: false,
    //   });
    //   navigate(`${window.location.pathname}?${queryRequest}`);
    // } else {
    //   toast.error("All fields are mandatory");
    // }
    
    let selectedTagsListIdsPayload = JSON.parse(
      JSON.stringify(selectedTagsListIds)
    );
    let selectedIndustryListIdsPayload = JSON.parse(
      JSON.stringify(selectedIndustryListIds)
    );
    let selectedStatesListIdsPayload = JSON.parse(
      JSON.stringify(selectedStatesListIds)
    );
    
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let payload: any = {
      ...params,
      page_no: params.page_no,
      country_id: getValue(request, `country_id`, ""),
      state_id: getValue(request, `state_id`, ""),
      search_text: getValue(params, `search_text`, ""),
      limit: getValue(params, `limit`, ""),
      industries:
        selectedIndustryListIdsPayload.length > 0
          ? selectedIndustryListIdsPayload.map((item: object) =>
              getValue(item, `id`, "")
            )
          : [],
      states:
        selectedStatesListIdsPayload.length > 0
          ? selectedStatesListIdsPayload.map((item: object) =>
              getValue(item, `id`, "")
            )
          : [],
      tags:
        selectedTagsListIdsPayload.length > 0
          ? selectedTagsListIdsPayload.map((item: object) =>
              getValue(item, `id`, "")
            )
          : [],
      // effective_date:
    };
    let queryRequest = QueryRequestHelper({ ...payload });
    navigate(`${window.location.pathname}?${queryRequest}`);

  };

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  let country_ID = countryList.filter(
    (item: object) =>
      getValue(item, `id`, "") == getValue(request, `country_id`, "")
  );
  let state_ID = states.filter(
    (item: object) =>
      getValue(item, `id`, "") == getValue(request, `state_id`, "")
  );

  const [showBlogsPopup, setShowBlogsPopup] = useState(false);
  const [blogInfo, setBlogInfo] = useState({});
  const handleViewBlogs = async (data: object) => {
    // console.log(data);
    setShowBlogsPopup(true);
    setBlogInfo(data);
  };

  const handleEditBlog = async (data: object) => {
    // console.log(data);
    navigate(`/legal-update/edit/${getValue(data, `id`, "")}?${mainQueryRequest}`);
  };

  // console.log(legalList);

  const decodeBase64 = (content: any) => {
    let decodedContent: any;
    try {
      decodedContent = base64.decode(content);
    } catch (error) {
      decodedContent = content; // Fallback to original content if decoding fails
    }
    return decodedContent;
  };

  const [dropdownOptions] = useState([
    { value: "LEGAL_UPDATE", label: "Legal Update" },
  ]);

  const [uploadLoader, setUploadLoader] = useState(false);
  const handleLegalUpdateImport = async (e: any) => {
    try {
      setUploadLoader(true);
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      let resp = await adminImportLegalUpdate(formData);
      if (resp) {
        e.target.value = null;
        toast.success("Imported Successfully");
        if (getValue(resp, `data.downloadUrl`, "")) {
          handleDownloadOnClick(
            getValue(resp, `data.downloadUrl`, ""),
            "Checkpoint Error Report"
          );
        }
        getData();
        setUploadLoader(false);
      } else {
        e.target.value = null;
        setUploadLoader(false);
      }
    } catch (error) {
      e.target.value = null;
      setUploadLoader(false);
    }
  };

  // Export
  const handleLegalUpdateExport = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    let dates = params.date ? new Date(params.date) : new Date();
    var from_date = params.from_date
      ? params.from_date
      : new Date(dates.getFullYear(), dates.getMonth(), 1);
    var to_date = params.to_date
      ? params.to_date
      : new Date(dates.getFullYear(), dates.getMonth() + 1, 0);

    let payload: any = {
      from_date: getValue(params, `from_date`, "")
        ? getValue(params, `from_date`, "")
        : convertDate(from_date),
      to_date:getValue(params, `to_date`, "")
        ? getValue(params, `to_date`, "")
        : convertDate(to_date),
      // compliance_status: getValue(params, `compliance_status`, ""),
      page_no: params.page_no ? parseInt(params.page_no) : 1,
      page_size: params.limit ? parseInt(params.limit) : limit,
      sort_by: params.sort_by,
    };

    try {
      let resp = await adminExportLegalUpdate(payload);
      if (resp) {
        handleDownloadOnClick(
          getValue(resp, `data.downloadUrl`, ""),
          "LegalUpdate"
        );
        // toast.success('Exported Successfully')
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                              Date Routes                                   */
  /* -------------------------------------------------------------------------- */
  const [from_date, setFirstDay] = React.useState(new Date());
  const [to_date, setLastDay] = React.useState(new Date());
  const handleDate = (date: any) => {
    setFirstDay(date[0]);
    setLastDay(date[1]);
  };

  const datePickerRef = useRef<any>(null); // Specify the type explicitly

  const handleSubmitDate = () => {
    let first = convertDate(from_date);
    let last = convertDate(to_date);
    if (first && last && first !== "Invalid date" && last !== "Invalid date") {
      let payload: any = {
        ...params,
        from_date: first,
        to_date: last,
      };
      if (payload.from_date && payload.to_date) {
        let queryRequest = QueryRequestHelper(payload);
        navigate(`${window.location.pathname}?${queryRequest}`);
        if (datePickerRef.current) {
          datePickerRef.current.setOpen(false);
        }
      } else {
        toast.error("Start Date and End Date Required");
      }
    } else {
      toast.error("Please select date");
    }
  };

  const resetDate = () => {
    delete params.from_date;
    delete params.to_date;
    let payload: any = {
      ...params,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
    let dates = new Date();
    var from_date = new Date(dates.getFullYear(), dates.getMonth(), 1);
    var to_date = new Date(dates.getFullYear(), dates.getMonth() + 1, 0);
    setFirstDay(from_date);
    setLastDay(to_date);
  };

  const handleCancelDate = () => {
    datePickerRef.current.setOpen(false);
    resetDate();
    getData();
  };
  
    // Multi States
    const [statesList, setStatesList] = useState([]);
    const [selectedStatesListIds, setSelectedStatesListIds] = useState<any>([]);
    const handleChangeStateMulti = (e: any) => {
      setSelectedStatesListIds(e);
    };
  
    const getStateList = async (value: string, id: string) => {
      try {
        let payload = await getPayload();
        let queryRequest = QueryRequestHelper({ ...payload, country: "101" });
        // let resp = await adminGetSCStatesListManagement(queryRequest);
        let resp = await adminGetStatesListManagement(101);
        if (resp) {
          let list: any = getValue(resp, `data.states`, []).map((item: object) => ({
            ...item,
            id: getValue(item, `id`, ""),
            value: getValue(item, `name`, ""),
            label: getValue(item, `name`, ""),
          }));
  
          let selectedIDs: any = urlSearchParams.getAll("states[]");
          setStatesList(list);
  
          let selectedList =
            getValue(list, `length`, 0) > 0
              ? list.filter((item: object) =>
                  selectedIDs.includes(getValue(item, `id`, []).toString())
                )
              : [];
          setSelectedStatesListIds(selectedList);
        } else {
          // setEventLoading(false);
        }
      } catch (error) {
        // setEventLoading(false);
      }
      if (!value) {
        setValue(request, `industry_id`, "");
      }
    };
  
    // Tags List
    const [tagsList, setTagsList] = useState([]);
    const [selectedTagsListIds, setSelectedTagsListIds] = useState<any>([]);
    const handleChangeTagsMulti = (e: any) => {
      setSelectedTagsListIds(e);
    };
  
    const getTagsList = async (value: string, id: string) => {
      try {
        let payload = {
          page_no: 1,
          page_size: 10,
          search_text: value,
          id: id,
          // group_id: "36", //
          group_code: "tags",
        };
        let queryRequest = QueryRequestHelper(payload);
        let resp = await adminListManagementList(queryRequest);
        if (resp) {
          let list: any = getValue(resp, `data.listItems`, []).map(
            (item: object) => ({
              ...item,
              value: getValue(item, `text`, ""),
              label: getValue(item, `text`, ""),
            })
          );
          let selectedIDs: any = urlSearchParams.getAll("tags[]");
          setTagsList(list);
  
          let selectedList =
            getValue(list, `length`, 0) > 0
              ? list.filter((item: object) =>
                  selectedIDs.includes(getValue(item, `id`, []).toString())
                )
              : [];
          setSelectedTagsListIds(selectedList);
        } else {
          // setEventLoading(false);
        }
      } catch (error) {
        // setEventLoading(false);
      }
      if (!value) {
        setValue(request, `industry_id`, "");
      }
    };
  
    //Industry List
    const [industryList, setIndustryList] = useState([]);
    const [selectedIndustryListIds, setSelectedIndustryListIds] = useState<any>(
      []
    );
    const handleChangeSelectMulti = (e: any) => {
      setSelectedIndustryListIds(e);
    };
  
    const getIndustryList = async (value: string, id: string) => {
      try {
        let payload = {
          page_no: 1,
          page_size: 10,
          search_text: value,
          id: id,
          // group_id: "36", //
          group_code: "industry",
        };
        let queryRequest = QueryRequestHelper(payload);
        let resp = await adminListManagementList(queryRequest);
        if (resp) {
          let list: any = getValue(resp, `data.listItems`, []).map(
            (item: object) => ({
              ...item,
              value: getValue(item, `text`, ""),
              label: getValue(item, `text`, ""),
            })
          );
          let selectedIDs: any = urlSearchParams.getAll("industries[]");
          setIndustryList(list);
  
          let selectedList =
            getValue(list, `length`, 0) > 0
              ? list.filter((item: object) =>
                  selectedIDs.includes(getValue(item, `id`, []).toString())
                )
              : [];
          setSelectedIndustryListIds(selectedList);
        } else {
          // setEventLoading(false);
        }
      } catch (error) {
        // setEventLoading(false);
      }
      if (!value) {
        setValue(request, `industry_id`, "");
      }
    };

    console.log(effective_date_from, effective_date_to, "====Effective====>")

  return (
    <DashboardLayout
      permissions={getValue(props, `tabPermissionList`, [])}
      subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
    >
      {!isLoading &&
      !getValue(props, `subTabPermissionList`, []).includes(
        "legal_update_legal_update"
      ) ? (
        <NoAccessPage />
      ) : (
        <>
          <div className="dashboard-wrapper__header d-flex justify-content-between align-items-center">
            <h4 className="dashbaord-wrapper__header-title">Legal Update</h4>
            <div>
            <CommonDateRange
              from_date={from_date}
              to_date={to_date}
              handleDate={handleDate}
              handleCancelDate={handleCancelDate}
              handleSubmitDate={handleSubmitDate}
              datePickerRef={datePickerRef}
            />
          </div>
          </div>

          

          <div className="admin-wrapper position-relative">
            <SearchHeader
              route={`/legal-update/add?${mainQueryRequest}`}
              searchInput={search_text}
              showSearchTextbox={showSearchTextbox}
              toggleSearch={toggleSearch}
              toggleSearchClose={toggleSearchClose}
              handleChangeSearch={handleChangeSearch}
              smartFilter={true}
              setFilterFormVisibility={() => {
                setFilterVisibility(!isFilterVisible);
              }}
              isFilterVisible={isFilterVisible}
              //   add={
              //     getValue(props, `permissions`, []).includes("create")
              //       ? "true"
              //       : "false"
              //   }
              add={"true"}
              dropdownOptions={dropdownOptions}
              commonUpload
              handleImport={handleLegalUpdateImport}
              uploadLoader={uploadLoader}
              isBulkUploadVisible={
                getValue(props, `permissions`, []).includes("update")
                  ? true
                  : false
              }
              log_value={"LEGAL_UPDATE"}
              export={
                getValue(props, `permissions`, []).includes("update")
                  ? true
                  : false
              }
              handleExport={handleLegalUpdateExport}
            />
          </div>

          {isFilterVisible && (
            <LegalFilterForm
              setFilterFormVisibility={() => {
                setFilterVisibility(!isFilterVisible);
              }}
              reset={reset}
              handleSubmitSmartFilter={handleSubmitSmartFilter}
              resetInput={resetInput}

              //States Multi
              statesList={statesList}
              handleChangeStateMulti={handleChangeStateMulti}
              selectedStatesListIds={selectedStatesListIds}
              //Tags
              tagsList={tagsList}
              handleChangeTagsMulti={handleChangeTagsMulti}
              selectedTagsListIds={selectedTagsListIds}
              //Industry
              industryList={industryList}
              handleChangeSelectMulti={handleChangeSelectMulti}
              selectedIndustryListIds={selectedIndustryListIds}

              //Effective Date
              effective_date_from={effective_date_from}
              effective_date_to={effective_date_to}
              handleEffectiveDate={handleEffectiveDate}

              // // smart filter
              // selectedSmartFilterIds={selectedSmartFilterIds}
              // handleChangeSelectMulti={handleChangeSelectMulti}
              // additionalFilter={true}
              // smartFilterList={smartFilterList}
              // request={request}
              // //dynamic filter ( Custom Input Select Dropdown)
              // //----> category of law
              // categoryOfLawList={categoryOfLawList}
              // getCategoryLawList={getCategoryLawList}
              // categoryLawLoading={categoryLawLoading}
              // cat_ID={cat_ID}
              // //----> legislation
              // legislationList={legislationList}
              // getLegislationList={getLegislationList}
              // legislationLoading={legislationLoading}
              // leg_ID={leg_ID}
              // //----> legislation rule
              // ruleList={ruleList}
              // getLegislationRuleList={getLegislationRuleList}
              // rule_ID={rule_ID}
              // legislationRuleLoading={legislationRuleLoading}
            />


          )}
          <div>
            {isLoading || !props.permissionAPITriggered ? (
              <Loader />
            ) : legalList.length > 0 ? (
              <>
                <div className="legal-update-main-wrap">
                  <div className="legal-update-card-holder">
                    {legalList.map((legalObj: any, index: any) => {
                      const concatString = (str: string, length: number) => {
                        return str
                          ? str.length > length
                            ? str.substring(0, length).concat("...")
                            : str
                          : null;
                      };
                      // const content = getValue(legalObj, 'content', '');
                      // const decodeBase64 = (content:any) => {
                      //   let decodedContent;
                      //   try {
                      //     decodedContent = base64.decode(content);
                      //   } catch (error) {
                      //     console.error('Error decoding base64:', error);
                      //     decodedContent = content; // Fallback to original content if decoding fails
                      //   }
                      //   return decodedContent;
                      // };

                      //   // Function to render HTML content safely using dangerouslySetInnerHTML
                      // const renderHTML = (htmlContent:any) => {
                      //   return { __html: htmlContent };
                      // };

                      let html: any = concatString(
                        decodeBase64(getValue(legalObj, "content", "")),
                        250
                      );
                      return (
                        <div className="legal-update-card">
                          <div className="legal-tags-wrap d-flex">
                            {getValue(legalObj, `tags`, []).map(
                              (tagObj: any, index: any) => {
                                return (
                                  <>
                                    <div
                                      className="legal-tags"
                                      title={getValue(tagObj, `text`, "")}
                                    >
                                      {getValue(tagObj, `text`, "")}
                                    </div>
                                  </>
                                );
                              }
                            )}

                            <div
                              className="legal-tags states-name-tags"
                              title={getValue(legalObj, `states.name`, "")}
                            >
                              {getValue(legalObj, `states.name`, "")}
                            </div>
                          </div>

                          <div className="legal-title">
                            {getValue(legalObj, `title.length`, "") > 80
                              ? getValue(legalObj, `title`, "")
                                  .substring(0, 80)
                                  .concat("...")
                              : getValue(legalObj, `title`, "")}
                          </div>
                          <div className="legal-timestamp">
                            {convertCurrentDateWithTime(
                              getValue(legalObj, `updated_at`, "")
                            )}
                          </div>
                          <div
                            className="legal-desc"
                            dangerouslySetInnerHTML={{ __html: html }}
                          ></div>
                          <div className="text-right read-more-link">
                            <Tooltip id="edit-tooltip" />
                            <Tooltip id="view-tooltip" />
                            <Tooltip id="delete-tooltip" />
                            <a
                              onClick={() => {
                                handleEditBlog(legalObj);
                              }}
                              data-tooltip-id="edit-tooltip"
                              data-tooltip-content="Edit"
                            >
                              {/* Edit */}
                              <EditSvgComponent />
                            </a>
                            <a
                              onClick={() => {
                                handleViewBlogs(legalObj);
                              }}
                              data-tooltip-id="view-tooltip"
                              data-tooltip-content="View"
                            >
                              {/* View More */}
                              <ViewMoreSvgComponent />
                            </a>
                            <a onClick={() =>
                                handleDeleteFun(
                                  getValue(legalObj, `id`, ''),
                                  getValue(legalObj, `comments`, '')
                                )
                              }
                              data-tooltip-id="delete-tooltip" 
                              data-tooltip-content="Delete"
                              >
                                <DeleteSvgComponent />
                            </a>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            ) : (
              <>
                {Object.keys(params).length !== 0 ? <Notfound /> : <Notfound />}
              </>
            )}
          </div>
          <div className="footer-wrapper d-flex justify-content-between">
            {legalList.length > 0 && (
              <img
                src="/images/building-illustration.svg"
                className="img-fluid"
              />
            )}
            {totalCount > 10 ? (
              <PaginationPage
                totalCount={totalCount}
                limit={limit}
                pageNumber={page_no}
                handleChangePagination={handleChangePagination}
                handleChangeLimit={handleChangeLimit}
              />
            ) : (
              ""
            )}
          </div>
          <DeleteModal
            isOpen={isDelete}
            handleModal={handleDelete}
            handleSubmit={handleDeleteFunction}
            deleteValue={deleteValue}
          />
          <BlogsViewPopup
            showBlogsPopup={showBlogsPopup}
            setShowBlogsPopup={setShowBlogsPopup}
            // editorState={editorState}
            // onEditorStateChange={onEditorStateChange}
            blogInfo={blogInfo}
          />
        </>
      )}
    </DashboardLayout>
  );
};

export default LegalUpdate;
