import "./filter-form.scss";
// import AddFilterNew from "../AddFilterNew";
import InputField from "@_common/InputRuleFeild";
import SearchableDropdownSelect from "@_common/SearchableDropdown";
import { getValue } from "@utils/lodash";
import { forwardRef } from "react";
import DatePicker from "react-datepicker";
import { Col, Row } from "reactstrap";

export default function LegalFilterForm(props: any) {
	const {
		handleChangeSelect,
		countryList,
		states,
		request,
		//loading...
		categoryLawLoading,
		legislationLoading,
		legislationRuleLoading,
		resetInput,


		industryList,
		selectedIndustryListIds,
		handleChangeSelectMulti,
		regulationList,
		selectedRegulationListIds,
		handleChangeRegulationMulti,
		ministryList,
		selectedMinistryListIds,
		handleChangeMinistryMulti,
		departmentList,
		selectedDepartmentListIds,
		handleChangeDepartmentMulti,
		notificationTypeList,
		selectedNotificationTypeListIds,
		handleChangeNotificationTypeMulti,
		statesList,
		handleChangeStateMulti,
		selectedStatesListIds,
		tagsList,
		handleChangeTagsMulti,
		selectedTagsListIds,

		effective_date_from,
		effective_date_to,
		handleEffectiveDate,
	} = props;

	const CustomInput = forwardRef<HTMLInputElement, any>(({ value, onClick }, ref) => (
		<input
		  type="text"
		  className="react-date-picker__inputGroup__input"
		  onClick={onClick}
		  ref={ref}
		  placeholder="Select Date Range"
		  value={value} // Controlled by react-datepicker
		  readOnly // To avoid manual input
		/>
	  ));
	
	
	return (
		<div className="filter-form-wrappper w-100 active filter-form-legal-update">
			<div className="filter-form-wrappper__container">
				<div className="d-flex align-items-center justify-content-between my-3">
					<h6 className="filter-form-wrappper__title">Filter by</h6>
					<img
						src="/images/close-black.svg"
						className="img-fluid cursor-pointer"
						// onClick={props.setFilterFormVisibility}
						onClick={() => {
							
							// Call the visibility toggle function
							props.reset();
							props.setFilterFormVisibility();
						  }}
						
					/>
				</div>
				<div className="d-flex justify-content-between gap">
					<div className="filter-form-wrappper__location flex-1">
						<div className="filter-form-wrappper__location-form-group form-group">
							<label htmlFor="state" className="filter-form-wrappper__label">
								State
							</label>
                            <div className="filter-form-wrappper__location-input select-dd-new">
							<InputField
								inputType="INPUT_REACT_SELECT"
								placeholder="Choose State"
								className="form-control w-100"
								name="state_id"
								id="state_id"
								label="Last Name"
								options={statesList}
								value={selectedStatesListIds}
								onChange={(e: any) =>
									handleChangeStateMulti(e)
								}
								isMulti
							/>
                          </div>
						</div>

						<div className="filter-form-wrappper__location-form-group form-group">
							<label htmlFor="rule" className="filter-form-wrappper__label filter-fields-label" >
								Effective Date
							</label>
							
							<div className="filter-form-wrappper__location-input select-dd-new">
								<DatePicker
									selectsRange={true}
									startDate={effective_date_from}
									endDate={effective_date_to}
									onChange={(update: any) => {
										handleEffectiveDate(update);
									}}
									dateFormat="d MMM yyyy"
									isClearable={true}
									showMonthDropdown
									showYearDropdown
									dropdownMode="select"
									className="react-date-picker__inputGroup__input"
									placeholder="Select Date Range"
									customInput={<CustomInput />}
								/>
							</div>
						</div>

						
							
					</div>
					<div className="filter-form-wrappper__location flex-1">
						<div className="filter-form-wrappper__location-form-group form-group">
								<label htmlFor="law" className="filter-form-wrappper__label">
									Tags
								</label>
								
								<div className="filter-form-wrappper__location-input select-dd-new">
									<InputField
										inputType="INPUT_REACT_SELECT"
										placeholder="Choose Tags"
										className="form-control w-100"
										name="tags_id"
										id="tags_id"
										label="Tags"
										options={tagsList}
										value={selectedTagsListIds}
										onChange={(e: any) =>
											handleChangeTagsMulti(e)
										}
										isMulti
									/>
								</div>
							</div>
					</div>

					<div className="filter-form-wrappper__location flex-1">
						<div className="filter-form-wrappper__location-form-group form-group">
							<label htmlFor="rule" className="filter-form-wrappper__label filter-fields-label" >
                            	Industry
                          	</label>
							
							<div className="filter-form-wrappper__location-input select-dd-new">
								<InputField
								inputType="INPUT_REACT_SELECT"
								placeholder="Choose Industry"
								className="form-control"
								name="legislation_rule_id"
								id="legislation_rule_id"
								label="Legislation Rule ID"
								options={industryList}
								value={selectedIndustryListIds}
								onChange={(e: any) => handleChangeSelectMulti(e)}
								isMulti
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="filter-form-wrappper__cta-wrapper">
				<button
					className="filter-form-wrappper__cta-btn filter-form-wrappper__cta-btn--search"
					onClick={props.handleSubmitSmartFilter}
				>
					Search
				</button>
				<button
					className="filter-form-wrappper__cta-btn filter-form-wrappper__cta-btn--reset"
					onClick={props.reset}
				>
					Reset
				</button>
			</div>
			{/* <AddFilterNew /> */}
		</div>
	);
}
